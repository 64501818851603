import React, { useState, useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import AddEditEmployeeMaster from "./AddEditEmployeeMaster";
import ResponsiveTable from "../../utils/ResponsiveTable";
import {
  getEmployee,
  deleteEmployee,
} from "../../services/user-service";
import editIcon from "../../assets/icons/EditIcon.svg";
import deleteIcon from "../../assets/icons/DeleteIcon.svg";
import CustomBreadcrumb from "../../utils/CustomBreadcrumb";
import Alert from "../common/Alert";
import constants from "../../utils/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate} from 'react-router-dom';
import { getAllRoles } from "../../services/role-service";

const EmployeeMaster = (props) => {
  const [action, setAction] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState({});
  const [reload, setReload] = useState(false);
  const [data, setData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const navigate = useNavigate();
  const headers = [
    {
      displayName: "Status",
      columnName: "Status",
      dataType: "text",
      setStatus: setStatus,
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Employee Name",
      columnName: "EmpName",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Role Code",
      columnName: "RoleCode",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Employee Email",
      columnName: "EmpEmail",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Login ID",
      columnName: "LoginID",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Region",
      columnName: "Region",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
  ];

  const handleAdd = () => {
    setAction("Add");
    setShowForm(true);
  };

  useEffect(() => {
    setLoading(true);
    const data = getEmployee();

    Promise.all([data])
      .then((responses) => {
        setLoading(false);
        setData(responses[0]);
      })
      .catch((error) => {
        setLoading(false);
        Alert("error", error);
      });
  }, [reload]);

  useEffect(() => {
    const data = getAllRoles();

    Promise.all([data])
      .then((responses) => {
        console.log(responses);
        setRoleData(responses[0])
      })
      .catch((error) => {
        setRoleData(null);
      });
  }, []);

  function setStatus(item) {
    let result = getEmployee(item);
    result
      .then((resp) => {
        Alert("success", "Status Updated Successfully");
        getEmployee();
      })
      .catch((error) => {
        Alert("error", error);
      });
  }
  let icons = [
    {
      src: editIcon,
      srcd: deleteIcon,
      click: edit,
      clicks: delet,
    },
  ];
  function edit(item) {
    setAction("Edit");
    setRowData(item);
    setShowForm(true);
  }
  function delet(item) {
    const MySwal = withReactContent(Swal);
    let title = "Delete Employee";
    let confirmationMessage = "Are you sure, you want to Delete this Employee?";
    MySwal.fire({
      title: title,
      html: confirmationMessage,
      ...constants.CONFIRMATION_BOX_CONFIG,
    }).then(async (selection) => {
      if (selection.isConfirmed) {
        setLoading(true);
        let result;
        let emitem = {
          EmpEmail: item.EmpEmail,
        };
        result = deleteEmployee(emitem);
         result
        .then((resp) => {
          Alert("success", resp.Message);
            if (item.EmpEmail === sessionStorage.getItem("Email")){
               navigate('/');
               window.location.reload();                 
            }
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
      setReload(!reload);
      }
    });
  }

  return (
    <>
      {loading && (
        <div className="spinner-box">
          {" "}
          <Spinner animation="border" className="spinner"></Spinner>
        </div>
      )}
      {showForm && (
        <AddEditEmployeeMaster
          show={showForm}
          action={action}
          rowdata={rowData}
          setReload={() => setReload(!reload)}
          projectData={data}
          onHide={() => setShowForm(false)}
          roledata={roleData}
        />
      )}
      <CustomBreadcrumb parentMenu={"Master"} menu={"Maintain Employeess"} setHeaderName={props.setHeaderName}>
        {" "}
      </CustomBreadcrumb>
      <Container bsPrefix="main-body">
        {/* <div className="page-title">Maintain Employees</div> */}
        <div className="page-sub-title">Add or Edit Employee Master</div>
        <ResponsiveTable
          headers={headers}
          data={data}
          icons={icons}
          dataType={"Maintain Employees"}
          button={"New Employee"}
          handleAdd={handleAdd}
          sessionName={"Maintain Employees"}
        ></ResponsiveTable>
      </Container>
    </>
  );
};
export default EmployeeMaster;
