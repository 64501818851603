import React, { useState, useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import AddEditUserMaster from "./AddEditUserMaster";
import ResponsiveTable from "../../utils/ResponsiveTable";
import editIcon from "../../assets/icons/EditIcon.svg";
import deleteIcon from "../../assets/icons/DeleteIcon.svg";
import CustomBreadcrumb from "../../utils/CustomBreadcrumb";
import Alert from "../common/Alert";
import { getUser, deleteUsers } from "../../services/master-service";
import constants from "../../utils/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { formatDateForDisplay } from "../../utils/common-util"


const UserMaster = (props) => {
  const [action, setAction] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState({});
  const [reload, setReload] = useState(false);
  const [data, setData] = useState([]);
  const [msg, setMsg] = useState("");
    const [errorMessage, setErrorMessage] = useState();
  const [applicableInData, setApplicableInData] = useState([]);
  const [headers, setHeaders] = useState([
    {
      displayName: "Status",
      columnName: "active_status",
      dataType: "text",
      setStatus: setStatus,
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "User Name",
      columnName: "user_name",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Mobile Number",
      columnName: "mobile",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Dealer Code",
      columnName: "dealer_code",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "User Type",
      columnName: "user_type",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Organization",
      columnName: "organization",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Region",
      columnName: "region",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Created By",
      columnName: "CreatedBy",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Created On",
      columnName: "CreatedOn",
      dataType: "text",
      width: 15,
      displayIndication: true,
      formatter:formatDateForDisplay
    },
  ]);

  const handleAdd = () => {
    setAction("Add");
    setShowForm(true);
  };

  useEffect(() => {
    setLoading(true);
    const data = getUser();

    Promise.all([data])
      .then((responses) => {
        console.log(responses);
        setLoading(false);
        setData(responses[0]);
      })
      .catch((error) => {
        setLoading(false);
        Alert("error", error);
      });
  }, [reload]);

  function setStatus(item) {
    let result = getUser(item);
    result
      .then((resp) => {
        Alert("success", "Status Updated Successfully");
        getUser();
      })
      .catch((error) => {
        Alert("error", error);
      });
  }
  let icons = [
    {
      src: editIcon,
      srcd: deleteIcon,
      click: edit,
      clicks: delet,
    },
  ];
  function edit(item) {
    setAction("Edit");
    setRowData(item);
    setShowForm(true);
  }
  function delet(item) {
    const MySwal = withReactContent(Swal);
    let title = "Delete User";
    let confirmationMessage = "Are you sure, you want to Delete this User?";
    MySwal.fire({
      title: title,
      html: confirmationMessage,
      ...constants.CONFIRMATION_BOX_CONFIG,
    }).then(async (selection) => {
      if (selection.isConfirmed) {
        setLoading(true);
        let result;
        let mobitem = {
          mobile: item.mobile,
        };
        result = deleteUsers(mobitem);
        result
        .then((resp) => {
          Alert("success", resp.Message);
          
        })
        .catch((err) => {
          setErrorMessage(err);
        });
      setLoading(false);
      setReload(!reload);
      }
    });
  }
  return (
    <>
      {loading && (
        <div className="spinner-box">
          {" "}
          <Spinner animation="border" className="spinner"></Spinner>
        </div>
      )}
      {showForm && (
        <AddEditUserMaster
          show={showForm}
          action={action}
          setMsg={setMsg}
          rowdata={rowData}
          setReload={() => setReload(!reload)}
          projectData={data}
          applicableInData={applicableInData}
          onHide={() => setShowForm(false)}
        />
      )}
      <CustomBreadcrumb parentMenu={"Master"} menu={"Maintain Users"} setHeaderName={props.setHeaderName}>
        {" "}
      </CustomBreadcrumb>
      <Container bsPrefix="main-body">
        {/* <div className="page-title">Maintain User Master</div> */}
        <div className="page-sub-title">Add or Edit User Master</div>
        <ResponsiveTable
          headers={headers}
          data={data}
          icons={icons}
          dataType={"Maintain Users"}
          button={"New User"}
          handleAdd={handleAdd}
          sessionName={"User Master"}
        ></ResponsiveTable>
      </Container>
    </>
  );
};

export default UserMaster;
