import axios from "axios";

const ITOKEN = process.env.REACT_APP_INTERACTTOKEN;

axios.interceptors.request.use(
    config => {
        if(config.url.includes("/public/track/events")){            
           config.headers['Content-Type'] = 'application/json'
           config.headers['Authorization'] = 'Basic ' + ITOKEN;
        }else{
           const token = sessionStorage.getItem('Token');
           if (token) {
               config.headers['Authorization'] = 'Bearer ' + token;
           }

       }
       return config;
   },
    error => {
        Promise.reject(error)
    });


axios.interceptors.response.use((response) => { 
    return response  
}, function (error) { 
 
    console.log("errorincpt", error)
    if (error?.response?.data?.Message) {
        return Promise.reject(error.response.data.Message);
    }
    if (ITOKEN){
        return Promise.reject(error)
    } else {
        return Promise.reject("Unable to process request");
    }
});
