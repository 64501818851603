import { Container } from "react-bootstrap";
import CustomBreadcrumb from "../../utils/CustomBreadcrumb";
import Header from "./Header";

export default function Dashboard(props) {
    return (<>
    {/* <Header headerName={"Dashboard"}></Header> */}
        <CustomBreadcrumb dashboard={"Dashboard"} setHeaderName={props.setHeaderName}>
        </CustomBreadcrumb>
        <Container bsPrefix="main-body">
            {/* <div className="page-title">Dashboard Page</div> */}
        </Container>
    </>)
}

