import React, { useEffect, useState } from "react";
import constants, { MENUS, ROLES } from "../../utils/constants";
import { Button, Form, Modal, Row, Col, Image,  Spinner } from "react-bootstrap";
import { AddNewRole, } from "../../services/role-service"
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import xclose from "../../assets/icons/Cross-Button.svg"
import Alert from "../common/Alert";
import InputBox from "../common/InputBox";
import DropdownBox from "../common/DropDownBox";

const AddEditRole = (props) => {
    const MySwal = withReactContent(Swal);
    const [parent, setParent] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [errorMessage, setErrorMessage] = useState();
    const [submit, setSubmit] = useState(false);
    const [fieldCount, setFieldCount] = useState([]);

    let action = props.action;
    const [menus, setMenus] = useState(JSON.parse(JSON.stringify(MENUS)));
   

    const [roleObj, setRoleObj] = useState(
        action === "Edit"
            ? props.rowdata
            :
            {
                RoleName: "",
                RoleCode: "",              
                AuthorizedSessions: ""
            }
    );
   

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRoleObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    useEffect(() => {      
        if (action === "Edit") {
            setEditData();
        }
        setError();
        setErrorMessage()
    }, [action])

    useEffect(() => {
        let errempty=true
        for (let err in error) {          
          if( error[err] !==""){
            errempty=false
            break;
          }

        }
        if(errempty){
            setErrorMessage(); 
        }
       
    }, [error])

    const setEditData = () => {
        let temp = {};
        for (let key in JSON.parse(JSON.stringify(MENUS))) {
            temp[key] = [];
            if (!props?.rowdata?.AuthorizedSessions || !props?.rowdata?.AuthorizedSessions[key]) {
                temp[key] = JSON.parse(JSON.stringify(MENUS))[key];
            } else {
                for (let menu1 of JSON.parse(JSON.stringify(MENUS))[key]) {
                    let found = false;
                    for (let menu2 of JSON.parse(JSON.stringify(props.rowdata.AuthorizedSessions))[key]) {
                        if (menu1.SubMenu == menu2.SubMenu) {
                            temp[key].push(menu2);
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        temp[key].push(menu1);
                    }
                }
            }
        }
        setMenus(temp);
    }

    useEffect(() => {
        let selectAll = true;
        let tempParent = {}
        for (let parentMenu in menus) {
            tempParent[parentMenu] = true
            for (let menu of menus[parentMenu]) {
                if (!menu.Read) {
                    tempParent[parentMenu] = false;
                    selectAll = false;
                }
            }
        }
        setSelectAll(selectAll)
        setParent(tempParent)
    }, [menus])

    const handleChangeSelectAll = () => {
        let temp = menus
        for (let parentMenu in temp) {
            if (temp[parentMenu].length > 0) {
                for (let menu of temp[parentMenu]) {
                    menu.Read = !selectAll;
                    if (menu.Write) {
                        menu.Read = true;
                    }

                }
            }
        }
        setMenus((prevState) => ({
            ...prevState,
            ...temp
        }));
        setSelectAll(!selectAll)
    }

    const handleChangeCheckbox = (parentMenu, subMenu, Type, checked) => {

        let temp = menus[parentMenu]
        for (let menu of temp) {
            if (menu.SubMenu == subMenu) {
                menu[Type] = checked;
                if (Type == "Write" && checked) {
                    menu.Read = checked;
                }
                if (menu.Write) {
                    menu.Read = true;
                }
                break;
            }
        }
        setMenus((prevState) => ({
            ...prevState,
            [parentMenu]: temp,
        }));
    }

    const handleParentChangeCheckbox = (parentMenu, checked) => {
        let temp = menus[parentMenu]
        for (let menu of temp) {
            menu.Read = checked;
            if (menu.Write) {
                menu.Read = true;
            }
        }
        setMenus((prevState) => ({
            ...prevState,
            [parentMenu]: temp,
        }));
    }

    const handleReset = () => {
        if (action === "Add") {
            setRoleObj((prevState) => ({
                ...prevState,
                RoleName: "",
                RoleCode: "",              
                AuthorizedSessions: "",
            }));
            setMenus(JSON.parse(JSON.stringify(MENUS)));
        } else if (action === "Edit") {
            setRoleObj(props.rowdata)
            setEditData();
        }

        setError();
        setErrorMessage()
    }


    const submitForm = (e) => {
       
        e.preventDefault();
        setSubmit(submit ? false : true);
    }

    useEffect(() => {
        const fieldValidation = 1;
        if (fieldCount.length >= fieldValidation) {
            setSubmit(submit ? false : true);
            handleSubmit();
            setFieldCount([]);
        }
    }, [fieldCount, action])

    const handleSubmit = async () => {
        if (!validateDetails()) {
            let confirmationMessage = action === "Add" ? "Are you sure do you want to Add New Role & Authorization?" :  "Are you sure do you want to Edit Selected Role & Authorization?" ;
            let title = action === "Add" ? "Add New Role & Authorization" : "Edit Role & Authorization" ;
            MySwal.fire({
                title: title,
                html: confirmationMessage,
                ...constants.CONFIRMATION_BOX_CONFIG
            }).then(async (selection) => {
                if (selection.isConfirmed) {
                    setLoading(true);
                    let result;
                    roleObj.RoleCode =roleObj.RoleName;
                    if (action === "Add") {                      
                        if (props.roledata.filter((item) => item.RoleCode.toLowerCase() === roleObj.RoleCode.toLowerCase()).length > 0) {
                            setErrorMessage("Role Code already exists.");
                            setLoading(false);
                            return;
                        }
                    }

                    let temp = {}
                    for (let parentMenu in menus) {
                        let tempArray = menus[parentMenu].filter((item) => item.Read)
                        if (tempArray.length > 0) {
                            temp[parentMenu] = tempArray;
                        }
                    }
                    roleObj.AuthorizedSessions = temp;
                    roleObj.masterName = "Role & Authorization"                    
                    delete roleObj.AuthorizedSessionsForDisplay;
                    result = AddNewRole(roleObj).catch();
                    result.then((resp) => {
                        Alert("success", resp.Message)
                        props.onHide();
                        props.setReload();
                    }).catch((err) => {
                        setErrorMessage(err);
                    })
                    setLoading(false);
                }
            });
        }
        else {
            setLoading(false);
        }
    }
  
    const validateDetails = () => {
        let tempError = false;
        for (let errorObj in error) {
            if (error[errorObj]) {
                tempError = true;
                break;
            }
        }
        if (tempError) {
            setErrorMessage("Please check data validation errors above.")
        } else {
            setErrorMessage("")
        }
        return tempError;
    }
    const commonProps = { setError: setError, error: error, submit: submit, setFieldCount: setFieldCount, fieldCount: fieldCount, onChange: handleChange }


    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner">
            </Spinner></div>}
            <Modal
                {...props}
                size="lg"
                backdrop="static"
            >
                <Modal.Header>
                    <Image src={xclose} onClick={props.onHide}>
                    </Image>
                    <Modal.Title className="modal-title">
                        {action} {action == "Add" ? "New" : ""} Role & Authorization
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  
                    <Row >
                        <Col lg={4} md={12} className="txt-input">
                        {(action === "Add") && (<>
                        <DropdownBox  label="Role Name"  name="RoleName" value={roleObj.RoleName}  required={"true"}  readOnly={action === "Edit" ? true : undefined} {...commonProps}>
                            <option value={""}>Select Role</option>
                                        {ROLES ? ROLES.map((role) => (
                                            <option key={role} value={role} >{role}</option>
                                        )) : null
                                        }
                                </DropdownBox> 
                                </>)}
                                {(action === "Edit") && (<>
                                    <InputBox type="text" label="Role Name" maxLength={50} name="Role Name" value={roleObj.RoleName} required={"true"} readOnly={true} {...commonProps} />
                                    </>)}
                           
                        </Col>
                        <Col lg={4} md={12} className="txt-input">
                       
                            <InputBox type="text" label="Role Code" maxLength={50} name={roleObj.RoleName==""?"Role Code":"Role Name"} value={roleObj.RoleName} readOnly={true} required={"true"}   />
 </Col>
                      
                    </Row>

                    <div className="tabledata-format">
                        <Row className="select-all-row">

                            <Form.Group className="menuhead-col" controlId="SelectAll">
                                <Form.Check type="checkbox" className="checkbox-check" readOnly id="SelectAll"
                                    onChange={handleChangeSelectAll}
                                    checked={selectAll}
                                    label=" Select All Menu Item" />
                            </Form.Group>

                        </Row>
                        <Row className="text-size" >
                            <Col lg={6} md={6} xs={9} className="menuhead-col" >
                                <Row> <Col className="menuhead-col session-col">Sessions</Col></Row></Col>
                            <Col> Editable</Col>


                        </Row>
                        {Object.keys(menus).map((parentMenu, parentIndex) => {
                            return (
                                menus[parentMenu].length > 0 ? <React.Fragment key={"key"+parentIndex}>
                                    <Row key={parentMenu} className={"parentmenu-row"} aria-controls={parentMenu} >
                                        <Col lg={6} md={6} xs={9} className="menuhead-col">

                                            <Form.Check type="checkbox" className="checkbox-check parent" label={parentMenu}
                                                checked={parent[parentMenu]}
                                                onChange={(e) => {
                                                    console.log("parentMenu ", parentMenu, " ", e.target)
                                                    handleParentChangeCheckbox(parentMenu, !parent[parentMenu]);
                                                }} />

                                        </Col>
                                        
                                    </Row>
                                 
                                        <div id={parentMenu}>
                                            {menus[parentMenu].map((item, index) => {
                                                return (
                                                    <Row key={"key"+index} className={"submenu-row"} >
                                                        <Col lg={5} md={5} xs={9} className="menuhead-col submenuhead-col">
                                                            <Form.Check type="checkbox"
                                                                className="checkbox-check"
                                                                id={item.SubMenu} label={item.SubMenu}
                                                                checked={item.Read}
                                                                onChange={(e) => {
                                                                    handleChangeCheckbox(parentMenu, item.SubMenu, "Read", !item.Read);
                                                                }} />

                                                        </Col>
                                                        <Col  ><Form.Check
                                                            type="checkbox"
                                                            className="checkbox-check center-align"
                                                            name={item.SubMenu + index}
                                                            checked={item.Write}
                                                            onChange={(e) => {
                                                                handleChangeCheckbox(parentMenu, item.SubMenu, "Write", !item.Write);
                                                            }} />
                                                        </Col>


                                                    </Row>

                                                )
                                            })}
                                        </div>
                                 
                                </React.Fragment> :
                                    <></>

                            )
                        })

                        }
                    </div>


                    <Row>
                        <br></br>
                    </Row>
                    <Row>
                        <Col>
                            {errorMessage && (
                                <Form.Text className="text-danger">
                                    {errorMessage}
                                </Form.Text>
                            )}
                            <hr></hr>

                        </Col>
                    </Row>
                       
                    <Row>
                        <div className="actionbtn">
                            <Button className="btn btn-outline-primary reset-button" onClick={handleReset} variant="outline-primary">Reset</Button>
                            <Button className="btn semibold-togglebutton right-alignement btn-color" type="submit" onClick={submitForm}>
                                {action === "Edit" ? "Update" : "Save"}
                            </Button>
                        </div>
                    </Row>

                </Modal.Body>
            </Modal >

        </>
    );
}

export default AddEditRole;
