import constants from "./constants";

export function searchAll(props, obj) {
    let filteredResult = props.data.filter(
        (item) => mulipleSearch(item, obj)
    );
    props.setData(filteredResult);
}

export function addInSearchList(obj, searchText, columnName, index) {

    if (obj && obj[columnName]) {
        obj[columnName][index] = searchText ? searchText : "";
    } else {
        obj[columnName] = { [index]: searchText }
    }

    return obj;
}

export function compare(filterTypeInput, text1, text2, text3) {
    let isFound = false;
    text1 = text1 && typeof text1 == "string" ? text1.toLowerCase() : text1;
    text2 = text2 && typeof text2 == "string" ? text2.toLowerCase() : text2;
    text3 = text3 && typeof text3 == "string" ? text3.toLowerCase() : text3;

    switch (filterTypeInput) {
        case "Equals":
            isFound = text1 == text2
            break;
        case "Not Equals":
            isFound = text1 != text2
            break;
        case constants.CONTAINS:
            text1 = text1?.toString();
            isFound = text1?.indexOf(text2) >= 0
            break;
        case "Not Contains":
            isFound = text1?.indexOf(text2) < 0

            break;
        case "Starts With":
            isFound = text1?.indexOf(text2) == 0
            break;
        case "Ends With":
            isFound = text1?.indexOf(text2) == text1.length - text2.length
            break;
        case "Blank":
            isFound = text1 == ""
            break;
        case "Not Blank":
            isFound = text1 != ""
            break;
        case "Greater Than or Equal":
            isFound = text1 >= text2
            break;
        case "Greater Than":
            isFound = text1 > text2
            break;
        case "Less Than or Equal":
            isFound = text1 <= text2
            break;
        case "Less Than":
            isFound = text1 < text2
            break;
        case "Range":
            isFound = text1 >= text2 && text1 <= text3
            break;
        default:
            break;
    }
    return isFound;
}

export function applyFilter(text1, obj) {
    let isFound1 = false;
    let isFound2 = false;
    // console.log("find", text1, obj[0], obj[1], obj.filterType, obj.filterType2, obj.condition)
    isFound1 = compare(obj.filterType, text1, obj[0], obj[1])

    if (obj.condition && obj.condition != "" && obj[1] && obj[1] != "") {
        isFound2 = compare(obj.filterType2, text1, obj[1]);
        // console.log("isFound2", isFound2)
        if (obj.condition == "AND") {
            return isFound1 && isFound2;
        }

        if (obj.condition == "OR") {
            return isFound1 || isFound2;
        }

    } else {
        return isFound1;
    }
}

export function mulipleSearch(item, searchList) {
    let isFound = true;
    for (let key of Object.keys(searchList)) {

        if (searchList[key][0] != "" || searchList[key][1] != "") {
            isFound = isFound && applyFilter(item[key], searchList[key])
        }
        // console.log("key", key, isFound, item[key], searchList[key])
    }
    return isFound;
}