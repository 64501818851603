import React, { useEffect, useState } from "react";
import Switch from "../../utils/Switch";
import { Button, Form, Modal, Row, Col, Spinner, Image } from "react-bootstrap";
import constants, {  USERTYPE } from "../../utils/constants";
import { addUser } from "../../services/master-service";
import Swal from "sweetalert2";
import xclose from "../../assets/icons/Cross-Button.svg";
import withReactContent from "sweetalert2-react-content";
import Alert from "../common/Alert";
import InputBox from "../common/InputBox";
import DropdownBox from "../common/DropDownBox";

const AddEditUserMaster = (props) => {
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [submit, setSubmit] = useState(false);
  const [fieldCount, setFieldCount] = useState([]);
  const [showAdditionalInput, setShowAdditionalInput] = useState(false);

  let action = props.action;
 
  const handleSwitchChange = (e) => {
    if (action !== "View") {
      setProjectObj((prevState) => ({
        ...prevState,
        active_status: e.target.checked ? "Active" : "Inactive",
      }));
    }
  };
 

  useEffect(() => {
    let errempty=true
    for (let err in error) {          
      if( error[err] !==""){
        errempty=false
        break;
      }

    }
    if(errempty){
        setErrorMessage(); 
    }
   
}, [error])

  useEffect(() => {
    setError();
    setErrorMessage();
  }, [props.action]);

  const submitForm = (e) => {
    e.preventDefault();
    setSubmit(submit ? false : true);
  };

  useEffect(() => {
    const fieldValidation = 2;
    if (fieldCount.length >= fieldValidation) {
      setSubmit(submit ? false : true);
      handleSubmit();
      setFieldCount([]);
    }
  }, [fieldCount, action]);

  const validateDetails = () => {
    let tempError = false;
    for (let errorObj in error) {
      if (error[errorObj]) {
        tempError = true;
        break;
      }
    }

    if (tempError) {
      setErrorMessage("Please check above errors");
    } else {
      setErrorMessage("");
    }
    return tempError;
  };

  const handleSubmit = async () => {
    if (!validateDetails()) {
      let confirmationMessage =
        action === "Add"
          ? "Are you sure do you want to Add New User?"
          : "Are you sure do you want to Edit User?" ;
      let title =
        action === "Add"
          ? "Add New User"
          : "Edit User" ;
      MySwal.fire({
        title: title,
        html: confirmationMessage,
        ...constants.CONFIRMATION_BOX_CONFIG,
      }).then(async (selection) => {
        if (selection.isConfirmed) {
          setLoading(true);
          let result;
          if (action === "Add") {
            if (props.projectData.length > 0) {
              if (
                props.projectData.filter(
                  (item) =>
                    item.mobile.toLowerCase() ===
                    projectObj.mobile.toLowerCase()
                ).length > 0
              ) {
                setErrorMessage("User already exists.");
                setLoading(false);
                return;
              }
            }
          }
          delete projectObj.CreatedOnForDispaly;
          result = addUser(projectObj);
          result
            .then((resp) => {
              Alert("success", resp.Message);
              props.onHide();
              props.setReload();
            })
            .catch((err) => {
              setErrorMessage(err);
            });
          setLoading(false);
        }
      });
    } else {
      return;
    }
  };


  const [projectObj, setProjectObj] = useState(() => {
    if (action === "Edit") {
      return props.rowdata;
    } else if (action === "View") {
      return props.rowdata;
    } else {
      return {
        mobile: "",
        active_status: "Active",
      };
    }
  });

  const handleReset = () => {
    if (action === "Add") {
      setShowAdditionalInput(false);
      setProjectObj((prevState) => ({
        ...prevState,
        mobile: "",
        user_type: "",
        user_name: "",
        dealer_code: "",
        organization: "",
        region: "",
        active_status: "Active",
      }));
    } else if (action === "Edit") {
      setProjectObj(props.rowdata);
    }
    setError();
    setErrorMessage();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "user_type") {
      const selectedValue = value;
      setShowAdditionalInput(selectedValue === "Dealer");
    }
    if (name === "organization" || name === "dealer_code") {
      setProjectObj((prevState) => ({
        ...prevState,
        [name]: value.toUpperCase(),
      }));
    } else {
    setProjectObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  };

  const commonProps = {
    setError: setError,
    error: error,
    submit: submit,
    setFieldCount: setFieldCount,
    fieldCount: fieldCount,
    onChange: handleChange,
  };
  console.log(props.rowdata);

  return (
    <>
      {loading && (
        <div className="spinner-box">
          {" "}
          <Spinner animation="border" className="spinner"></Spinner>
        </div>
      )}
      <Modal {...props} size="lg" backdrop="static" className="ProjectModal">
        <Modal.Header>
          <Image src={xclose} onClick={props.onHide}></Image>
          <Modal.Title className="modal-title">{action} User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={4} md={12} className="txt-input">
              <Row>
                <Col className="status-switch">
                  <Form.Label className="fieldLabel gray-shade lblstatus">
                    Status
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col className="sw-status">
                  <Switch
                    isOn={projectObj.active_status == "Active" ? true : false}
                    handleToggle={(e) => {
                      handleSwitchChange(e);
                    }}
                  ></Switch>
                  <Form.Label data-label={projectObj.active_status} className="fieldLabel statusCode">
                    {projectObj.active_status}
                  </Form.Label>
                </Col>
              </Row>
            </Col>

            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="User Name"
                maxLength={200}
                name="user_name"
                value={projectObj.user_name}
                required={true}
                isUserName={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Mobile Number"
                maxLength={10}
                name="mobile"
                value={projectObj.mobile}
                required={true}
                isMobileNo={true}
                readOnly={
                  action === "Edit" || action === "View" ? true : undefined
                }
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              {action === "Add" && (
                <DropdownBox
                    label="User Type"
                    name="user_type"
                    value={projectObj.user_type}
                    required={"true"}
                    readOnly={action === "Edit" ? true : undefined}
                    {...commonProps}
                  >
                    <option value={""}>Select User Type</option>
                    {USERTYPE
                      ? USERTYPE.map((Type) => (
                          <option key={Type} value={Type}>
                            {Type}
                          </option>
                        ))
                      : null}
                  </DropdownBox>
              )}
              {action === "Edit" && (
                <>
                  <DropdownBox
                    label="User Type"
                    name="user_type"
                    value={projectObj.user_type}
                    required={"true"}
                    readOnly={action === "Edit" ? true : undefined}
                    {...commonProps}
                  >
                    <option value={""}>Select User Type</option>
                    {USERTYPE
                      ? USERTYPE.map((Type) => (
                          <option key={Type} value={Type}>
                            {Type}
                          </option>
                        ))
                      : null}
                  </DropdownBox>{" "}
                </>
              )}
              {action === "View" && (
                <InputBox
                    type="text"
                    label="User Type"
                    name="user_type"
                    value={projectObj.user_type}
                    required={true}
                    readOnly={action === "Edit" || "View" ? true : undefined}
                    {...commonProps}
                  />
              )}
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Dealer Code"
                maxLength={50}
                name="dealer_code"
                value={projectObj.dealer_code}
                required={(showAdditionalInput) || (action==="Edit" && projectObj.user_type==="Dealer")}
                isAlphanumeric={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Organization"
                maxLength={200}
                name="organization"
                value={projectObj.organization}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Region"
                maxLength={50}
                name="region"
                value={projectObj.region}
                required={true}
                isAlphanumeric={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {errorMessage && (
                <Form.Text className="text-danger">{errorMessage}</Form.Text>
              )}
              <hr></hr>
            </Col>
          </Row>
          {action !== "View" && (
            <Row>
              <div className="actionbtn">
                <Button
                  className="btn btn-outline-primary reset-button"
                  onClick={handleReset}
                  variant="outline-primary"
                >
                  Reset
                </Button>
                <Button
                  className="btn btn-primary semibold-togglebutton right-alignement btn-color"
                  type="submit"
                  onClick={submitForm}
                >
                  {action === "Edit" ? "Update" : "Save"}
                </Button>
              </div>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddEditUserMaster;
