import axios from "axios";

const URL = process.env.REACT_APP_SSG_MASTER_API;



export async function getUser() {
    let url = URL + "/getAllUser";
    let response = await axios.get(url);
    return response.data
  };
  
  
  export const addUser = async (projectObj) => {
    let url = `${URL}/addUser`;
    const response = await axios.post(url, projectObj)
    return response.data;
  };
  
  
  export const deleteUsers = async (mobitem) => {
    let url = `${URL}/deleteUser`;
    const response = await axios.post(url, mobitem)
    return response.data;
  };