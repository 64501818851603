import axios from "axios"

const URL = process.env.REACT_APP_SSG_MASTER_API

export async function getAllProductImage() {
    let url = URL + "/getAllProductImage";
    let response = await axios.get(url);
    return response.data;
}


export const addProductImage = async (productimagedata) => {
    console.log("addProductImage ", productimagedata);
    let url = `${URL}/addProductImage`;
    console.log("url ", URL);
    const response = await axios.post(url, productimagedata)
    return response.data;
};

export const deleteProductImage = async (imageitem) => {
    let url = `${URL}/deleteproductimage`;
    const response = await axios.post(url, imageitem)
    return response.data;
  };

export const submitFiles = async (signedURL, file) => {
    if (signedURL) {
        await axios.put(signedURL, file);
    }
}