import React, { useState, useEffect } from "react";
import AddEditRole from "./AddEditRole";
import { Container, Spinner } from "react-bootstrap";
import constants from "../../utils/constants";
import ResponsiveTable from "../../utils/ResponsiveTable";
import editIcon from "../../assets/icons/EditIcon.svg";
import deleteIcon from "../../assets/icons/DeleteIcon.svg";
import { getAllRoles, deleteRole } from "../../services/role-service";
import CustomBreadcrumb from "../../utils/CustomBreadcrumb";
import Alert from "../common/Alert";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const RoleMaster = (props) => {
  const [action, setAction] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [reload, setReload] = useState(false);
  const [rowData, setRowData] = useState({});
  const [data, setData] = useState([]);  
  const [loading, setLoading] = useState(true);

  const handleAdd = () => {
    setAction("Add");
    setShowForm(true);
  };

  useEffect(() => {
    fetchRoleMaster();
  }, [reload]);
  const fetchRoleMaster = async () => {
    setLoading(true);
    const result = getAllRoles();

    console.log(" result ", result);

    Promise.all([result]).then((responses) => {
      setLoading(false);
      console.log(" responses[0] ", responses[0] );
      console.log(" responses[0].length ", responses[0].length );
      if (responses[0] && responses[0].length > 0) {
        responses[0].forEach(element => {
          let sessionArr = [];
          for (let key in element.AuthorizedSessions) {
            for (let menu of element.AuthorizedSessions[key]) {
              sessionArr.push(menu.SubMenu);
            }
            element.AuthorizedSessionsForDisplay = sessionArr.toString();
          }
        });
      }
      setData(responses[0])
    }).catch((error) => {
      setLoading(false);
      Alert("error",error);
    });
  };

  let headers = [   
    { displayName: "Role Code", columnName: "RoleCode", dataType: "text",width:10, displayIndication:true },
    { displayName: "Role Name", columnName: "RoleName", dataType: "text",width:20, displayIndication:true },
    { displayName: "Role Authorization", columnName: "AuthorizedSessionsForDisplay", dataType: "text",width:40, displayIndication:true },

  ]
  let icons = [{ src: editIcon,
    srcd: deleteIcon,
  
    click: edit,
    clicks: deleterole, }]

  function edit(item) {
    setAction("Edit");
    setRowData(item);
    setShowForm(true);
  }
 
 

  function deleterole(item) {
    const MySwal = withReactContent(Swal);
    let title = "Delete Role";
    let confirmationMessage = "Are you sure, you want to Delete this Role?";
    MySwal.fire({
      title: title,
      html: confirmationMessage,
      ...constants.CONFIRMATION_BOX_CONFIG,
    }).then(async (selection) => {
      if (selection.isConfirmed) {
        setLoading(true);
        let result;
        let roleitem = {
          RoleCode: item.RoleCode,
        };
        result = deleteRole(roleitem);
         result
        .then((resp) => {
          Alert("success", resp.Message);
          
        })
        .catch((err) => {
         console.log(err);
        });
      setLoading(false);
      setReload(!reload);
      }
    });
  }

  return (<>
    {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner">
    </Spinner></div>}
    {showForm && (
      <AddEditRole
        show={showForm}
        action={action}
        roledata={data}
        rowdata={rowData}
        setReload={()=> setReload(!reload)}
        onHide={() => setShowForm(false)}
      />
    )}

    <CustomBreadcrumb parentMenu={"Admin"} menu={"Role and Authorization"} setHeaderName={props.setHeaderName}>
    </CustomBreadcrumb>
    <Container bsPrefix="main-body">
      {/* <div className="page-title">Role & Authorization Master</div> */}
      <div className="page-sub-title">Add and Edit Role Details</div>
      <ResponsiveTable headers={headers} data={data} icons={icons} dataType={"Roles"} button={"New Role"} handleAdd={handleAdd} sessionName="Role and Authorization"></ResponsiveTable>
    </Container>
  </>)

}

export default RoleMaster;