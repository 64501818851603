import axios from "axios";

const URL = process.env.REACT_APP_SSG_MASTER_API;
const IURL = process.env.REACT_APP_INTERACTURL;

export const addProject = async (projectObj) => {
    let url = `${URL}/addProject`;
    const response = await axios.post(url, projectObj)
    return response.data;
  };

    export const addBulkProject = async (projectObj) => {
    let url = `${URL}/bulkuplod/addbulkproject`;
    const response = await axios.post(url, projectObj)
    return response.data;
  };
  
  export const addBulkHistoryProject = async (projectObj) => {
    let url = `${URL}/bulkuplod/addbulkhistoryproject`;
    const response = await axios.post(url, projectObj)
    return response.data;
  };
  export const updateBulkProject = async (projectObj) => {
    let url = `${URL}/bulkuplod/updatebulkproject`;
    const response = await axios.post(url, projectObj)
    return response.data;
  };
  
  export const addProjectHistory = async (projectObj) => {
    let url = `${URL}/addProjectHistory`;
    const response = await axios.post(url, projectObj)
    return response.data;
  };
  
  export const updateProject = async (projectObj) => {  
    console.log("updateproject ", projectObj);
    let url = `${URL}/updateproject`;  
    const response = await axios.post(url, projectObj)    
    return response.data;
  };
  
  export const deleteProject = async (projectitem) => {
    let url = `${URL}/deleteProject`;
    const response = await axios.post(url, projectitem)
    return response.data;
  };

  export const deleteProjectHistory = async (projectitem) => {
    let url = `${URL}/deleteprojecthistory`;
    const response = await axios.post(url, projectitem)
    return response.data;
  };
  
  export async function getProjects() {
    let url = URL + "/getAllProjects";
    let response = await axios.get(url);
    return response.data
  };

  export async function getHistoryProjects() {
    let url = URL + "/getAllHistoryProjects";
    let response = await axios.get(url);
    return response.data
  };

  export const addProjectAPI = async (projectapiObj) => {
    let url = IURL;
    const response = await axios.post(url, projectapiObj)
    return response.data;
  };