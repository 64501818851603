export function userlogo(name) {
    let userLogo = name.split(" ").map((n) => n[0]).join("");
    return userLogo == "" ? "AB" : userLogo;
}


export function getCurrentDateForDisplay(inputdate) {
    let date = new Date(inputdate);
    let tmpdate = date.toISOString().slice(0, 10).replaceAll("-", "/");
    return tmpdate.split("/")[2] + "/" + tmpdate.split("/")[1] + "/" + tmpdate.split("/")[0];

};

export function formatDateForDisplay(inputdate) {
    console.log("inputdate ", inputdate);
    if (inputdate && inputdate != "") {
        let date = new Date(inputdate);
        console.log("date formatDateForDisplay ", date);
        let tmpdate = date.toISOString().slice(0, 10).replaceAll("-", "/");
        return tmpdate.split("/")[2] + "/" + tmpdate.split("/")[1] + "/" + tmpdate.split("/")[0];
    }else{
        return inputdate;
    }

};