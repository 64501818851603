import { Route, Routes } from "react-router-dom";
import Sample from "../Sample";
import Dashboard from "./Dashboard";
import RoleMaster from "../Admin/RoleMaster";
import { Container } from "react-bootstrap";
import UserMaster from "../Master/UserMaster";
 import ProductImageMaster from "../Master/ProductImageMaster";
import EmployeeMaster from "../Admin/EmployeeMaster";
import UserFeedbackMaster from "../Master/UserFeedbackMaster";
import ProjectMaster from "../Master/ProjectMaster";
import { useState } from "react";

export default function Main(props) {
  
  return (
    <Container bsPrefix="main">
      <Routes>
        <Route exact path="/" element={<Dashboard setHeaderName={props.setHeaderName}/>}></Route>
        <Route exact path="/sample" element={<Sample setHeaderName={props.setHeaderName}/>}></Route>
        <Route exact path="/usermaster" element={<UserMaster setHeaderName={props.setHeaderName}/>}></Route>
        <Route exact path="/employeemaster" element={<EmployeeMaster setHeaderName={props.setHeaderName}/>}></Route>
        <Route exact path="/userfeedbackmaster" element={<UserFeedbackMaster setHeaderName={props.setHeaderName}/>}></Route>
        <Route exact path="/projectmaster" element={<ProjectMaster setHeaderName={props.setHeaderName}/>}></Route>
       
        <Route exact path="/role" element={<RoleMaster setHeaderName={props.setHeaderName}/>}></Route>
        <Route exact path="/productimage" element={<ProductImageMaster setHeaderName={props.setHeaderName}/>}></Route>
      </Routes>
    </Container>
  );
}
