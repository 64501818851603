import React, { useEffect, useState } from "react";
import { Navbar, Row , Image, Modal} from "react-bootstrap";
import { List, Person } from "react-bootstrap-icons";
import { useMsal } from "@azure/msal-react";
import { userlogo } from "../../utils/common-util";
import logoutarrow from "../../assets/logoutarrow.svg"

export default function Header(props) {
  const { instance } = useMsal();

  function handleLogout(instance) {
    sessionStorage.clear();
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }

  return (
    <>
    <Navbar bsPrefix="header">
      <div className="sidebar-head-header">
        <List className="head-toggle" onClick={() => { props.setSidebar(props.sidebar === "closed" ? "open" : "closed") }}></List>
      </div>
      {/* <div className="app-name">SSG Haptik Chatbot</div> */}
      <div className="app-name">{props.headerName}</div>
      <div className="user-section">
            <div className="user-logo">
              <span> {userlogo(sessionStorage.getItem("UserName"))}</span>
            </div>
            <div className="user-info">
              <span className="user-name">{sessionStorage.getItem("UserName")}</span>
              <span className="user-email">{sessionStorage.getItem("Email")}</span>
            </div>
            <div className="logout-arrow" onClick={() => {handleLogout(instance)}}></div>           
          </div>
    
    </Navbar>
     
        </>
  );
}
