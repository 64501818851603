import axios from "axios"

const URL = process.env.REACT_APP_SSG_MASTER_API;

export async function downloadAttachment(fileName, OnlyUrl) {
  let options = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem("Token")}` },
  };
  let s3fileName = encodeURIComponent(fileName);
  let url = `${URL}/filename/${s3fileName}`;
  const response = await axios
    .get(url, options)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to fetch File", status: false };
    });
  let preSignedUrl = response.data.fileUrl;
  if (OnlyUrl) {
    return preSignedUrl;
  }


  const link = document.createElement("a");
  link.href = preSignedUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

