import { Button, Container, Image } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth-config";
import logo from "../../assets/godrej-logo-dark.svg"
import chatbot from "../../assets/chatbot.jpg"

export default function Login(props) {
  const { instance } = useMsal();

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }

  return (
    <Container bsPrefix="login-container"  >
      <div className="d-flex justify-content-between align-items-center logoHeader">
        <Image src={logo} />
        <span className="headerLink">Godrej Enterprises Group</span>
      </div>

      <div className="container-fluid text-center vh-100 overflow-hidden">

        <div className="row align-items-start d-flex">
        <div class="col-12 col-md-8 noPadMar bg-primary loginBg">
         </div>

        <div className="col-12 col-md-4 login-box d-flex flex-column">
          {props.errorMessage && <div className=".text-label-with-error">{props.errorMessage}</div>
          }
          <div className="text-start mb-4 login-header">
            <h1>SSG</h1>
            <h5>Haptik Chatbot</h5>
          </div>

          <div class="text-start selectUser mt-2 lg:mt-4 mb-4">
          </div>
          
          <div className="mt-4 Login-btn-container">
            <Button onClick={() => handleLogin(instance)}
              className={"rounded-pill login-btn"} >
              LOGIN
            </Button>
          </div>
        </div>

        </div>
      </div>
    </Container>
  );
}
