import { Col, Container, Row } from "react-bootstrap";
import Header from "./Header";
import { BrowserRouter as Router } from "react-router-dom";
import SideBar from "./SideBar";
import Main from "./Main";
import { useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

export default function Home(props) {

  const [sidebar, setSidebar] = useState("closed");
  const [headerName, setHeaderName] = useState("")
  // console.log("header name state",headerName);
  
  return (
    <Router>
      <Row bsPrefix={`home ${sidebar}`}>
        <Col bsPrefix="side-bar">
          <SideBar setSidebar={setSidebar} sidebar={sidebar} />
        </Col>
        <Col bsPrefix="center-panel">
          <Header setSidebar={setSidebar} sidebar={sidebar} headerName={headerName}/>
          <Main setHeaderName={setHeaderName}/>
        </Col>
        <ToastContainer
          autoClose={process.env.TOAST_AUTO_CLOSE}
          className="toast-container"
          hideProgressBar={true}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={false}
          theme="colored"
          icon={false}
        />
      </Row>
    </Router>
    
      )
}