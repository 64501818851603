import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import "./InputBox.css"

const InputBox = (props) => {
    const [focus, setFocus] = useState(false);
    let classname=""
    
    if(props.hasOwnProperty("class")){
        classname = props.class;
      }

    const validateInput = () => {
        setFocus(false);
        allValidations();
    }
    const validateMandatory = () => {
        if ((!props.value || props.value === "")) {
            props.setError((prev) => ({ ...prev, [props.name]: props.label + " is mandatory" }));
        } else {
            props.setError((prev) => ({ ...prev, [props.name]: "" }));
        }
    }

    const pattern = /^[^']{1,200}$/;
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    const alphanumericRegexWSC = /^[a-z]+@godrej\.com$/;
    const mobileNumberRegex = /^[6-9]\d{9}$/;
    const userNameRegex = /^[A-Za-z\s]+$/;
    const decimalDigitRegex = /^[-+]?\d+(\.\d{1,2})?$/;
    const alphanumspecharRegex = /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-]+$/;
    const alphanumspaceRegex = /^\s*[a-zA-Z0-9\s]+\s*$/;




    const allValidations = () => {
        if (props.required) {
            validateMandatory()
        }
        if (props.value && props.value !== "") {
            if (props.customValidation) {
                let errMsg = props.customValidation(props.value);
                props.setError((prev) => ({ ...prev, [props.name]: errMsg }));
            }
            if (props.hasOwnProperty("isPattern")) {
                if (!pattern.test(props.value)) {
                    props.setError((prev) => ({ ...prev, [props.name]: props.label + " should not contain special characters Apostrophe (‘) " }));
                } 
                else {
                    props.setError((prev) => ({ ...prev, [props.name]: "" }));
                }
            }
            if (props.hasOwnProperty("min")) {
                if (props.value < props.min) {
                    props.setError((prev) => ({ ...prev, [props.name]: "Please enter value greater than " + [props.min] }));
                } else {
                    props.setError((prev) => ({ ...prev, [props.name]: "" }));
                }
            }
            if (props.hasOwnProperty("isAlphanumeric")) {
                if (!alphanumericRegex.test(props.value)) {
                    props.setError((prev) => ({ ...prev, [props.name]: props.label + " must be alphanumeric with no spaces" }));
                } 
                else {
                    props.setError((prev) => ({ ...prev, [props.name]: "" }));
                }
            }
            if (props.hasOwnProperty("isMobileNo")) {
                if (!mobileNumberRegex.test(props.value)) {
                    props.setError((prev) => ({ ...prev, [props.name]: props.label + " accept only 10 digit number starting with digits between 6 to 9 " }));
                } 
                else {
                    props.setError((prev) => ({ ...prev, [props.name]: "" }));
                }
            }
            if (props.hasOwnProperty("isEmail")) {
                if (!alphanumericRegexWSC.test(props.value)) {
                    props.setError((prev) => ({ ...prev, [props.name]: props.label + " accept only godrej Email" }));
                } 
                else {
                    props.setError((prev) => ({ ...prev, [props.name]: "" }));
                }
            }
            if (props.hasOwnProperty("isUserName")) {
                if (!userNameRegex.test(props.value)) {
                    props.setError((prev) => ({ ...prev, [props.name]: props.label + " accept only alphabets" }));
                } 
                else {
                    props.setError((prev) => ({ ...prev, [props.name]: "" }));
                }
            }
            if (props.hasOwnProperty("isDecimalDigit")) {
                if (!decimalDigitRegex.test(props.value)) {
                    props.setError((prev) => ({ ...prev, [props.name]: props.label + " accept only decimal number upto 2 digit" }));
                } 
                else {
                    props.setError((prev) => ({ ...prev, [props.name]: "" }));
                }
            }
            if (props.hasOwnProperty("isAlphanumspecharRegex")) {
                if (!alphanumspecharRegex.test(props.value)) {
                    props.setError((prev) => ({ ...prev, [props.name]: props.label + " accepts alphabets, numbers and special characters only" }));
                } 
                else {
                    props.setError((prev) => ({ ...prev, [props.name]: "" }));
                }
            }
            if (props.hasOwnProperty("isAlphanumspaceRegex")) {
                if (!alphanumspaceRegex.test(props.value)) {
                    props.setError((prev) => ({ ...prev, [props.name]: props.label + " accepts alphanumeric values" }));
                } 
                else {
                    props.setError((prev) => ({ ...prev, [props.name]: "" }));
                }
            }
        }
    }

    useEffect(() => {
        if (props.submit) {
            props.setFieldCount((prev) => ([...prev, props.name]));
            allValidations();
        }
    }, [props.submit]);

    return (
        <Form.Group className={`g-input`}>
            <Form.Control
                className={` ${props.error && props.error[props.name] ? "text-field-with-error " + classname  : classname}`}
                {...props}
                onBlur={(e) => { validateInput(); }}
                autoComplete="off"
                id={props.name}
                value={!props.value ? "" : props.value}
                onFocus={
                    () => { setFocus(true) }}
            />
            <Form.Label className={`${(focus || (props?.value && props?.value!="")) ? "active" : ""} ${props.error && props.error[props.name] ? "text-label-with-error" : ""}`} htmlFor={props.name}>
                {props.label}
                {(!props.required || props.readOnly == false) && <span className="optional"> (optional)</span>}
            </Form.Label>
            {props.error && props.error[props.name] && (
                <Form.Text className="text-danger">
                    {props.error[props.name]}
                </Form.Text>
            )}
        </Form.Group>
    )
}

export default InputBox;