
let constants = {
  CONTAINS: "Contains",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  EDIT:"Edit",
  MENUS: {
    "Admin": [
      { "SubMenu": "Role and Authorization", "Path": "/role" },
      { "SubMenu": "Maintain Employees", "Path": "/employeemaster" }],
    "Master": [
      { "SubMenu": "Product Image Master", "Path": "/productimage" }      ,
      { "SubMenu": "User Master", "Path": "/usermaster" }   ],
    "Transaction": [
        { "SubMenu": "User Feedback Master", "Path": "/userfeedbackmaster" }      ,
        { "SubMenu": "Project Master", "Path": "/projectmaster" }      ],
  
  },
 
 
  USERTYPE: ["Employee", "Dealer", "Customer"],
  ROLES:["Admin", "Employee"],
  DISPATCHTYPE:["PARTIAL", "FULL", "FINAL"],
  NOTIFICATIONTYPE:["Delay", "Prepone", "OrderBooked"],
  ORDERSTATUS:["Order Booked", "Planned Dispatch Date", "Revision in Planned Dispatch Date", "Order Dispatch", "Delivered", "Completed"],
  ALLOWEDATTACHMENTFILETYPE: ["pdf", "jpg", "png", "jpeg"],
  FIXED_COLUMN:["Status"],
  CONFIRMATION_BOX_CONFIG: {
    showCancelButton: true,
    confirmButtonText: "Yes",    
    cancelButtonText: "No",
    reverseButtons: true,
    showCloseButton: true,
    customClass: "swal-confirmation",
  },

};



module.exports = Object.freeze(constants);
