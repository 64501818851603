import axios from "axios"

const URL = process.env.REACT_APP_SSG_USER_API

export const AddNewRole = async (role) => { 
  console.log(" role ", role);
  let url = `${URL}/addRoleData`;
  const response = await axios.post(url, role);
  return response.data;
};


export function authenticate(email) {
  let url = URL + "/authenticate/" + email;
  return axios.get(url);
}

export async function getAllRoles() {
  console.log("getAllRoles_URL",URL + "/role")
  const response = await axios.get(URL + "/role");
  console.log("response_getAllRoles",response)
  return response.data;
}

export const deleteRole = async (rolecode) => {
  let url = `${URL}/deleterole`;
  const response = await axios.post(url, rolecode)
  return response.data;
};

