import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row, Col, Spinner, Image } from "react-bootstrap";
import constants from "../../utils/constants";
import { addUser } from "../../services/master-service";
import Swal from "sweetalert2";
import xclose from "../../assets/icons/Cross-Button.svg";
import withReactContent from "sweetalert2-react-content";
import Alert from "../common/Alert";
import InputBox from "../common/InputBox";

const AddEditFeedbackMaster = (props) => {
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [submit, setSubmit] = useState(false);
  const [fieldCount, setFieldCount] = useState([]);

  let action = props.action;
 
 
  useEffect(() => {
    setError();
    setErrorMessage();
  }, [props.action]);

  const submitForm = (e) => {
    e.preventDefault();
    setSubmit(submit ? false : true);
  };

  useEffect(() => {
    const fieldValidation = 2;
    if (fieldCount.length >= fieldValidation) {
      setSubmit(submit ? false : true);
      handleSubmit();
      setFieldCount([]);
    }
  }, [fieldCount, action]);

  const validateDetails = () => {
    let tempError = false;
    for (let errorObj in error) {
      if (error[errorObj]) {
        tempError = true;
        break;
      }
    }

    if (tempError) {
      setErrorMessage("Please check above errors");
    } else {
      setErrorMessage("");
    }
    return tempError;
  };

  const handleSubmit = async () => {
    if (!validateDetails()) {
      let confirmationMessage =
        action === "Add"
          ? "Are you sure do you want to Add New User Feedback?"
          :  "Are you sure do you want to Edit User Feedback?" ;
      let title =
        action === "Add"
          ? "Add New User Feedback"
          : "Edit User Feedback" ;
      MySwal.fire({
        title: title,
        html: confirmationMessage,
        ...constants.CONFIRMATION_BOX_CONFIG,
      }).then(async (selection) => {
        if (selection.isConfirmed) {
          setLoading(true);
          let result;
          if (action === "Add") {
            if (props.projectData.length > 0) {
              if (
                props.projectData.filter(
                  (item) =>
                    item.mobile.toLowerCase() ===
                    projectObj.mobile.toLowerCase()
                ).length > 0
              ) {
                setErrorMessage("User Feedback already exists.");
                setLoading(false);
                return;
              }
            }
          }
          delete projectObj.CreatedOnForDispaly;
          result = addUser(projectObj);
          result
            .then((resp) => {
              Alert("success", resp.Message);
              props.onHide();
              props.setReload();
            })
            .catch((err) => {
              setErrorMessage(err);
            });
          setLoading(false);
        }
      });
    } else {
      return;
    }
  };



  const [projectObj, setProjectObj] = useState(() => {
    if (action === "Edit") {
      return props.rowdata;
    } else if (action === "View") {
      return props.rowdata;
    } else {
      return {
        mobile: "",
        active_status: "Active",
      };
    }
  });

  const handleReset = () => {
    if (action === "Add") {
      setProjectObj((prevState) => ({
        ...prevState,
        mobile: "",
        user_type: "",
        user_name: "",
        dealer_code: "",
        organization: "",
        region: "",
        active_status: "Active",
      }));
    } else if (action === "Edit") {
      setProjectObj(props.rowdata);
    }
    
    setError();
    setErrorMessage();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;  
    setProjectObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const commonProps = {
    setError: setError,
    error: error,
    submit: submit,
    setFieldCount: setFieldCount,
    fieldCount: fieldCount,
    onChange: handleChange,
  };
  console.log(props.rowdata);

  return (
    <>
      {loading && (
        <div className="spinner-box">
          {" "}
          <Spinner animation="border" className="spinner"></Spinner>
        </div>
      )}
      <Modal {...props} size="lg" backdrop="static" className="ProjectModal">
        <Modal.Header>
          <Image src={xclose} onClick={props.onHide}></Image>
          <Modal.Title className="modal-title">{action} User Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="User Name"
                maxLength={200}
                name="user_name"
                value={projectObj.user_name}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Mobile Number"
                maxLength={10}
                name="mobile"
                value={projectObj.mobile}
                required={true}
                isMobileNo={true}
                readOnly={
                  action === "Edit" || action === "View" ? true : undefined
                }
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="date"
                label="Created Date"
                maxLength={50}
                name="created_date"
                value={projectObj.created_date}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Organization"
                maxLength={200}
                name="organization"
                value={projectObj.organization}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Installation Done By Experienced Manpower"
                maxLength={200}
                name="installation_done_by_experienced_manpower"
                value={projectObj.installation_done_by_experienced_manpower}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Resolved Problems"
                maxLength={200}
                name="resolved_problems"
                value={projectObj.resolved_problems}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Manpower Conduct and Descipline"
                maxLength={200}
                name="manpower_conduct_and_descipline"
                value={projectObj.manpower_conduct_and_descipline}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Installation Drawing Displayed"
                maxLength={200}
                name="installation_drawing_displayed"
                value={projectObj.installation_drawing_displayed}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Inline Solution Offered"
                maxLength={200}
                name="inline_solution_offered"
                value={projectObj.inline_solution_offered}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Safety Instructions Used"
                maxLength={200}
                name="safety_instructions_used"
                value={projectObj.safety_instructions_used}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Periodic Supplu Progress Reports"
                maxLength={200}
                name="periodic_supplu_progress_reports"
                value={projectObj.periodic_supplu_progress_reports}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Overall Experiance with Godrej"
                maxLength={200}
                name="overall_experiance_with_godrej"
                value={projectObj.overall_experiance_with_godrej}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Installation done by superwisers Guide"
                maxLength={200}
                name="installation_done_by_superwisers_guide"
                value={projectObj.installation_done_by_superwisers_guide}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Delivered as per schedule"
                maxLength={200}
                name="delivered_as_per_schedule"
                value={projectObj.delivered_as_per_schedule}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Suggestions"
                maxLength={200}
                name="suggestions"
                value={projectObj.suggestions}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Requirement Compiled with"
                maxLength={200}
                name="requirement_compiled_with"
                value={projectObj.requirement_compiled_with}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="COVID guideline and Procedure Followed"
                maxLength={200}
                name="COVID_guideline_and_Procedure_Followed"
                value={projectObj.COVID_guideline_and_Procedure_Followed}
                required={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {errorMessage && (
                <Form.Text className="text-danger">{errorMessage}</Form.Text>
              )}
              <hr></hr>
            </Col>
          </Row>
          {action !== "View" && (
            <Row>
              <div className="actionbtn">
                <Button
                  className="btn btn-outline-primary reset-button"
                  onClick={handleReset}
                  variant="outline-primary"
                >
                  Reset
                </Button>
                <Button
                  className="btn btn-primary semibold-togglebutton right-alignement btn-color"
                  type="submit"
                  onClick={submitForm}
                >
                  {action === "Edit" ? "Update" : "Save"}
                </Button>
              </div>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddEditFeedbackMaster;
