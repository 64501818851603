import React, { useState, useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import AddEditFeedbackMaster from "./AddEditUserFeedbackMaster";
import ResponsiveTable from "../../utils/ResponsiveTable";
import viewIcon from "../../assets/icons/View.svg";
import CustomBreadcrumb from "../../utils/CustomBreadcrumb";
import Alert from "../common/Alert";
import { getAllUsersFeedback } from "../../services/userfeedback-service";
import 'react-datepicker/dist/react-datepicker.css';
import { formatDateForDisplay } from "../../utils/common-util"



const UserFeedbackMaster = (props) => {
  const [action, setAction] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState({});
  const [reload, setReload] = useState(false);
  const [data, setData] = useState([]);
  const [msg, setMsg] = useState("");
  const [applicableInData, setApplicableInData] = useState([]); 
  const [headers, setHeaders] = useState([
    {
      displayName: "User Name",
      columnName: "user_name",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Mobile Number",
      columnName: "mobile",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Created Date",
      columnName: "created_date",
      dataType: "date",
      width: 15,
      displayIndication: true,
      formatter:formatDateForDisplay
    },
    {
      displayName: "Organization",
      columnName: "organization",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Installation done by Experienced Manpower",
      columnName: "installation_done_by_experienced_manpower",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Resolved Problems",
      columnName: "resolved_problems",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Manpower Conduct and Descipline",
      columnName: "manpower_conduct_and_descipline",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Installation Drawing Displayed",
      columnName: "installation_drawing_displayed",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Inline Solution Offered",
      columnName: "inline_solution_offered",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Safety Instructions Used",
      columnName: "safety_instructions_used",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Periodic Supplu Progress Reports",
      columnName: "periodic_supplu_progress_reports",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Overall Experiance with Godrej",
      columnName: "overall_experiance_with_godrej",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Installation Done By Superwisers Guide",
      columnName: "installation_done_by_superwisers_guide",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Delivered as per Schedule",
      columnName: "delivered_as_per_schedule",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Suggestions",
      columnName: "suggestions",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Requirement Compiled With",
      columnName: "requirement_compiled_with",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "COVID Guideline and Procedure Followed",
      columnName: "COVID_guideline_and_Procedure_Followed",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
  ]);


  const handleAdd = () => {
    setAction("Add");
    setShowForm(true);
  };

  useEffect(() => {
    setLoading(true);
    const data = getAllUsersFeedback();

    Promise.all([data])
      .then((responses) => {
        console.log(responses);
        setLoading(false);
        setData(responses[0]);
      })
      .catch((error) => {
        setLoading(false);
        Alert("error", error);
      });
  }, [reload]);

  
  let icons = [{srcv: viewIcon, clickv: view}];
  function view(item) {
    setAction("View");
    setRowData(item);
    setShowForm(true);
  }


  return (
    <>
      {loading && (
        <div className="spinner-box">
          {" "}
          <Spinner animation="border" className="spinner"></Spinner>
        </div>
      )}
      {showForm && (
        <AddEditFeedbackMaster
          show={showForm}
          action={action}
          setMsg={setMsg}
          rowdata={rowData}
          setReload={() => setReload(!reload)}
          projectData={data}
          applicableInData={applicableInData}
          onHide={() => setShowForm(false)}
        />
      )}
      <CustomBreadcrumb parentMenu={"Master"} menu={"Maintain User Feedback"} setHeaderName={props.setHeaderName}>
        {" "}
      </CustomBreadcrumb>
      <Container bsPrefix="main-body">
        {/* <div className="page-title">Maintain User Feedback</div> */}
        <div className="page-sub-title">View User Feedback</div>
        <ResponsiveTable
          headers={headers}
          data={data}
          icons={icons}
          dataType={"Maintain User Feedback"}
          handleAdd={handleAdd}
          sessionName={"User Feedback Master"}
          showDownload={true}
          excelDownload={true}
          downloadFileName={"UserFeedbackMaster.xlsx"}
        ></ResponsiveTable>
      </Container>
    </>
  );
};

export default UserFeedbackMaster;
